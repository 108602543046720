import {
  faAppleCore,
  faAtom,
  faBook,
  faBooks,
  faBrowser,
  faBug,
  faChalkboard,
  faChalkboardTeacher,
  faChartLine,
  faChartSimple,
  faChess,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faCircle5,
  faCircle6,
  faCircle7,
  faCircleCheck,
  faClipboard,
  faClipboardQuestion,
  faClock,
  faCode,
  faCommentsDollar,
  faDna,
  faDumbbell,
  faEnvelopeOpenText,
  faFileCertificate,
  faFileCheck,
  faFileDownload,
  faFillDrip,
  faFlask,
  faFlaskPotion,
  faGalaxy,
  faGamepad,
  faGlobeAmericas,
  faHome,
  faInboxes,
  faLayerGroup,
  faMapMarkedAlt,
  faMedal,
  faMemoPad,
  faMicroscope,
  faMoneyCheckEdit,
  faMugHot,
  faNewspaper,
  faNotebook,
  faPencilAlt,
  faPersonChalkboard,
  faPhone,
  faQuestionSquare,
  faRectangleTerminal,
  faRocketLaunch,
  faRunning,
  faSatellite,
  faSchool,
  faSearch,
  faStopwatch,
  faThumbsUp,
  faUserAstronaut,
  faUserChart,
  faUserCheck,
  faUserCrown,
  faUserEdit,
  faUserGraduate,
  faUserLock,
  faUserPlus,
  faUsers,
  faUserSecret,
  faVial,
  faList
} from '@fortawesome/pro-duotone-svg-icons';

export const fontAwesomeDuotoneIcons = {
  faUserGraduate,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faCircle5,
  faCircle6,
  faCircle7,
  faCircleCheck,
  faGalaxy,
  faBooks,
  faChalkboardTeacher,
  faUsers,
  faFlaskPotion,
  faGamepad,
  faThumbsUp,
  faBug,
  faRocketLaunch,
  faQuestionSquare,
  faBook,
  faHome,
  faMugHot,
  faFileCertificate,
  faRunning,
  faUserChart,
  faChalkboard,
  faUserEdit,
  faFlask,
  faUserCrown,
  faSchool,
  faVial,
  faAtom,
  faGlobeAmericas,
  faSatellite,
  faMicroscope,
  faDna,
  faPencilAlt,
  faMapMarkedAlt,
  faEnvelopeOpenText,
  faPhone,
  faUserLock,
  faLayerGroup,
  faCode,
  faBrowser,
  faSearch,
  faUserSecret,
  faFillDrip,
  faClock,
  faMoneyCheckEdit,
  faFileCheck,
  faChess,
  faDumbbell,
  faStopwatch,
  faUserCheck,
  faFileDownload,
  faUserAstronaut,
  faClipboardQuestion,
  faCommentsDollar,
  faInboxes,
  faNotebook,
  faMemoPad,
  faChartSimple,
  faUserPlus,
  faAppleCore,
  faClipboard,
  faNewspaper,
  faChartLine,
  faMedal,
  faPersonChalkboard,
  faRectangleTerminal,
  faList,
};
